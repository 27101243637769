import React from 'react'

const LOR3 = () => {
    return (
        <div className='lor-preview'>
            <iframe src="https://drive.google.com/file/d/1hUFCrJMTvw9gNuUsw6UyjlA4y8is2AkW/preview?usp=sharing" />
        </div>
    )
}

export default LOR3