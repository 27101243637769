import React from 'react'

const LOR1 = () => {
    return (
        <div className='lor-preview'>
            <iframe src="https://drive.google.com/file/d/1Cz1xNI1IktWO6NQajST6KhTko7jIJvL6/preview?usp=sharing" />
        </div>
    )
}

export default LOR1